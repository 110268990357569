









import {Component, Prop, Vue} from 'vue-property-decorator';
import {AD_FORMATS} from '@/model/ads/ads.model';

@Component
export default class HfAdPlaceholder extends Vue {

  @Prop({required: true}) formatKey!: string;

  get isFormatValid(): boolean {
    return !!AD_FORMATS[this.formatKey];
  }

  get adStyleClass(): string {
    let adId = '';
    if (this.$route.query.adId && this.formatKey !== 'rectangle') {
      adId = this.$route.query.adId;
    }
    return `ad ${this.formatKey}${adId}`;
  }

}
