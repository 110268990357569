import { render, staticRenderFns } from "./GoldbachAd.vue?vue&type=template&id=7efd17e3&scoped=true&"
import script from "./GoldbachAd.vue?vue&type=script&lang=ts&"
export * from "./GoldbachAd.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7efd17e3",
  null
  
)

export default component.exports