import Vue from 'vue';
import {Mixin} from 'vue-mixin-decorator';
import {MetaProperty} from '@/model/site/components.model';
import {FB_APP_ID, TWITTER_HANDLE} from '@/utils/common.util';
import {MetaInfo} from 'vue-meta';

/**
 * Components using this MetaMixin must implement HfPage.
 */
@Mixin
export default class MetaMixin extends Vue {

	get siteName(): string {
		return 'Hockeyfans.ch';
	}
	get metaInfo() {
		// note for og:local: https://bit.ly/2NHEFdG - de_CH is not allowed
		const metaInfo = {
			title: this.headTitle,
			link: [
				{rel: 'canonical', href: this.canonicalUrl}
			],
			meta: [
				// Base
				{vmid: 'description', name: 'description', content: this.headDescription},
				{vmid: 'name', name: 'name', content: this.headTitle},

				// Google / Schema.org
				{itemprop: 'name', content: this.headTitle},
				{itemprop: 'description', content: this.headDescription},

				// Facebook OpenGraph
				{property: 'fb:app_id', content: FB_APP_ID},
				{property: 'og:title', content: this.headTitle},
				{property: 'og:type', content: 'website'},
				{property: 'og:url', content: this.canonicalUrl, id: 'og:url'},
				{property: 'og:description', content: this.headDescription},
				{property: 'og:site_name', content: this.siteName},
				{property: 'og:locale', content: 'de_DE'},

				// Twitter card
				{name: 'twitter:card', content: 'summary'},
				{name: 'twitter:site', content: this.canonicalUrl},
				{name: 'twitter:title', content: this.headTitle},
				{name: 'twitter:description', content: this.headDescription},
				{name: 'twitter:creator', content: TWITTER_HANDLE},
			],
		};
		const imageDesc = this.imageDesc;
		if (imageDesc) {
			metaInfo.meta.push({itemprop: 'image', content: imageDesc.src});
			metaInfo.meta.push({property: 'og:image:src', content: imageDesc.src});
			metaInfo.meta.push({property: 'og:image', content: imageDesc.src});
			if (imageDesc.alt) {
				metaInfo.meta.push({property: 'og:image:alt', content: imageDesc.alt});
			}
			metaInfo.meta.push({name: 'twitter:image:src', content: imageDesc.src});
		}
		return metaInfo;
	}

	replaceMetaProperty(metaInfo: MetaInfo, propertyKey: string, content: string): MetaInfo {
		let replaced = false;
		if (metaInfo.meta) {
			metaInfo.meta.filter((p: any) => {
				return p.property === propertyKey || p.itemprop === propertyKey || p.name === propertyKey;
			}).forEach((p: any) => {
				replaced = true;
				p.content = content;
			});
		}
		if (!replaced) {
			Vue.$log.warn(`Property '${propertyKey}' not found to replace`);
		} else {
			Vue.$log.debug(`Property '${propertyKey}' replaced with '${content}'`);
		}
		return metaInfo;
	}
}
