









































import {Component, Vue} from 'vue-property-decorator';
import HfAdBox from '@/components/ads/HfAdBox.vue';
import SideBoxes from '@/components/layout/SideBoxes.vue';
import { Box } from '@/model/site/components.model';
import { Prop } from 'vue-property-decorator';

@Component({
  components: {SideBoxes, HfAdBox},
})
export default class SportLayout extends Vue {
  @Prop({type: Array, default: () => []}) boxes?: Box[];
  @Prop({type: Boolean, default: false}) isMobile?: boolean;
  @Prop({type: Boolean, default: true}) showAds?: boolean;
}
