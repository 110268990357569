import { render, staticRenderFns } from "./RosterPlayerTitle.vue?vue&type=template&id=4312b47c&scoped=true&functional=true&"
import script from "./RosterPlayerTitle.vue?vue&type=script&lang=ts&"
export * from "./RosterPlayerTitle.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  true,
  null,
  "4312b47c",
  null
  
)

export default component.exports