















import {Component, Prop, Vue} from 'vue-property-decorator';
import COMMON_UTIL from '@/utils/common.util';
import * as Sentry from '@sentry/browser';
import {Severity} from '@sentry/browser';

@Component({
  components: {},
})
export default class PlayerPicture extends Vue {

  @Prop({required: true}) playerId!: string;
  @Prop({validator: PlayerPicture.validSize}) size?: string;

  static validSize(value: any): boolean {
    return ['regular', 'small', 'medium'].indexOf(value) !== -1;
  }

  get path() {
    return COMMON_UTIL.paths.playerPortrait(this.playerId);
  }

  get altPath() {
    return COMMON_UTIL.paths.playerPortraitPlaceholder();
  }

  get sizeClass() {
    if (this.size === 'regular') {
      return 'is-87x122';
    }
    if (this.size === 'small') {
      return 'is-35x50';
    }
    if (this.size === 'medium') {
      return 'is-50x70';
    }
    return 'is-87x122';
  }

  onError(error: any) {
    const src = error.path[0].src;
    if (!src.endsWith(this.altPath)) {
      const msg = `No picture for ${this.playerId}, using placeholder instead`;
      Vue.$log.warn(msg);
      error.path[0].src = this.altPath;
    }
  }


}
