














import {Component, Prop, Vue} from 'vue-property-decorator';
import HfAdPlaceholder from '@/components/ads/HfAdPlaceholder.vue';
import {Getter} from 'vuex-class';
import {AD_FORMAT_KEYS, AD_FORMATS, AdModel} from '@/model/ads/ads.model';
import {GET_DEVELOPER_MODE, GET_SHOW_ADS} from '@/store/user/getters';
import MobileMixin from '@/mixins/mobile.mixin';
import GoldbachAd from '@/components/ads/GoldbachAd.vue';

@Component(
  {
    mixins: [MobileMixin],
    components: {GoldbachAd, HfAdPlaceholder},
  })
export default class HfAdBox extends Vue {

  @Prop({required: true}) formatKey!: string;
  @Prop({type: Array, default: () => []}) targets!: string[];

    @Getter(GET_SHOW_ADS) showAds!: boolean;
    @Getter(GET_DEVELOPER_MODE) develMode!: boolean;

    get showOnTarget() {
      if (this.targets && this.targets.length === 0) {
        return true;
      }
      let showOnTarget = false;
      if (this.targets.find((target) => target === 'desktop') === 'desktop') {
        showOnTarget = this.isDesktop() || showOnTarget;
      }
      if (this.targets.find((target) => target === 'tablet') === 'tablet') {
        showOnTarget = this.isTablet() || showOnTarget;
      }
      if (this.targets.find((target) => target === 'mobile') === 'mobile') {
        showOnTarget = this.isMobile() || showOnTarget;
      }
      return showOnTarget;
    }

    get adModel(): AdModel | undefined {
      if (this.formatKeyPlus && AD_FORMATS[this.formatKeyPlus]) {
        return AD_FORMATS[this.formatKeyPlus];
      }
      return undefined;
    }

    get formatKeyPlus() {
      let formatKeyPlus = this.formatKey;
      if (this.formatKey.endsWith('Mobile') || this.formatKey.endsWith('Tablet')) {
        return this.formatKey;
      }

      if (this.formatKey === AD_FORMAT_KEYS.leaderboardBig) {
        return this.formatKey;
      }

      if (this.isMobile()) {
        formatKeyPlus = `${this.formatKey}Mobile`;
        if (AD_FORMATS['formatKeyPlus']) {
          return formatKeyPlus;
        }
      }

      if (this.isTablet()) {
        formatKeyPlus = `${this.formatKey}Tablet`;
        if (AD_FORMATS['formatKeyPlus']) {
          return formatKeyPlus;
        }
        // TODO [ad] define skyscraperTablet
      }

      return formatKeyPlus;
    }

    get adId(): string | undefined {
      return this.adModel && this.adModel.id;
    }

    get style() {
      if (!this.adModel || !this.adModel.style) {
        return '';
      }
      return `display:inline-block;${this.adModel.style};`;
    }

  }
