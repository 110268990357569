
























import {Component, Vue} from 'vue-property-decorator';
import PanelBox from '@/components/ui/PanelBox.vue';
import HfAdBox from '@/components/ads/HfAdBox.vue';
import { Prop } from 'vue-property-decorator';
import { Box } from '@/model/site/components.model';
import PlayerPicture from '@/components/player/PlayerPicture.vue';
import HfTeamLogo from '@/components/shared/HfTeamLogo.vue';
import MobileMixin from '@/mixins/mobile.mixin';
import RosterPlayerTitle from '@/components/player/RosterPlayerTitle.vue';
import CountryFlag from '@/components/shared/CountryFlag.vue';

@Component({
  mixins: [MobileMixin],
  components: {CountryFlag, RosterPlayerTitle, HfTeamLogo, PlayerPicture, HfAdBox, PanelBox},
})
export default class SideBoxes extends Vue {
  @Prop(Array) boxes?: Box[];

  properties(box: Box) {
    return {...box.props};
  }
}
