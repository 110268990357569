






















import {Component, Prop, Vue} from 'vue-property-decorator';
import CountryFlag from '@/components/shared/CountryFlag.vue';
import Player from '@/model/core/player.model';
import {playerStatsLink} from '@/router';
import {COUNTRY_SERVICE} from '@/services/country.service';

@Component({
  components: {CountryFlag},
})
export default class RosterPlayerTitle extends Vue {
  @Prop({type: Object, required: true}) player!: Player;

  playerLink(playerId: string) {
    return playerStatsLink(playerId);
  }

  iso2Code(nationalityId: string): string | undefined {
    return COUNTRY_SERVICE.hfToCountryIso2(nationalityId);
  }

}
