









import {Component, Vue} from 'vue-property-decorator';
import { Prop } from 'vue-property-decorator';
import { AdModel } from '@/model/ads/ads.model';

@Component({
  components: {},
})
export default class GoldbachAd extends Vue {
  @Prop({required: true, type: Object}) adModel!: AdModel;
  @Prop({required: true, type: String}) formatKey!: string;

  adEnabled: boolean = true;
  showAds: boolean = true;

  mounted() {
    // Vue.$log.debug('*** mounted', this.adModel.id);
    this.enableAds('mounted');
  }

  created() {
    // Vue.$log.debug('*** created', this.adModel.id);
    // this.enableAds('created');
  }

  updated() {
    // Vue.$log.debug('*** updated', this.adModel.id);
    this.enableAds('updated');
  }

  activated() {
    // Vue.$log.debug('*** activated', this.adModel.id);
    this.enableAds('activated');
  }

  deactivated() {
    // Vue.$log.debug('*** deactivated', this.adModel.id);
  }

  get inlineStyle() {
    if (this.adModel.id === 'div-ad-gds-2403-3') {
      return this.adModel.style;
    }
    return '';
}


  private enableAds(hookId: string) {
    try {
      if (!this.adEnabled) {
        if (this.showAds) {
          this.addAdScript(this.adModel, hookId);
        }
      }
    } catch (error) {
      this.$log.error(`Error while activating GoldbachAd '${this.formatKey}' in ${hookId}`, error);
    } finally {
      this.adEnabled = true;
    }
  }

  private addAdScript(ad: AdModel | undefined, hookId: string) {
    if (!ad) {
      return;
    }
    const divId = ad.id;
    const adFunctionName = ad.functionName;
    const adContainerDiv = document.getElementById(divId);
    if (adContainerDiv) {
      if (adContainerDiv.offsetParent === null) {
        // means not visible
        Vue.$log.debug(`Ad-element for '${this.formatKey}' is not visible`);
        return;
      }
      this.$log.debug(`Enabled GoldbachAd '${this.formatKey}' in '${hookId}'`);

      // remove old ad-element
      const adElementDiv = adContainerDiv.getElementsByTagName('div').item(0);
      if (adElementDiv) {
        adContainerDiv.removeChild(adElementDiv);
      }

      // create ad-script element
      const adScript = document.createElement('script');
      const timeoutMs = 500;
      adScript.setAttribute('type', 'application/javascript');
      adScript.text = `
        console.debug('GoldbachAd: calling "${adFunctionName}/${divId}"');
        setTimeout(function() {
          try {
            ${adFunctionName}('${divId}', '');
            console.debug('GoldbachAd: "${adFunctionName}/${divId}" has been executed');
          } catch (error) {
            console.error('error executing ad-function for "${divId}"', error);
          }
        }, ${timeoutMs});
      `;
      const existingScript = adContainerDiv.children[0];
      if (existingScript) {
        adContainerDiv.replaceChild(adScript, existingScript);
      } else {
        adContainerDiv.appendChild(adScript);
      }
    }
  }
}
